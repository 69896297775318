import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from '@reach/router'

const HeroSectionPageUpdate = ({ imgSrc, title, withButton, withNewCTAButton, withUpdateCTAButton, withUpdateNewCTAButton, withSimpleCTAButton, withOnlyCTAButton, redBubble, redBubbleText }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionPageUpdateQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 500,
          }}
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
              <div className="flex flex-col justify-center">
                <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                  {title}
                </h2>

                {withSimpleCTAButton
                  ?
                  <div className="hidden xl:flex flex-col items-center justify-center mt-8">
                    <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6">
                        <span className="default-text">Do You Qualify?</span>
                        <span className="hover-text mx-7">Begin Now</span>
                      </button>
                    </Link>

                    <Link to="/contact">
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-10 py-3">
                        Request a Consultation
                      </button>
                    </Link>
                  </div>
                  :
                  null
                }

                {withUpdateCTAButton
                  ?
                  <div className="hidden xl:flex flex-col items-center justify-center mt-8">
                    <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                        <span className="default-text mx-2">Do You Qualify?</span>
                        <span className="hover-text">Begin Assessment</span>
                      </button>
                    </Link>
                    
                    <button 
                      onClick={() => {navigate('#apply')}}
                      className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-12 py-3"
                    >
                      Become a Partner
                    </button>
                  </div>
                  :
                  null
                }

                {withUpdateNewCTAButton
                  ?
                  <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
                    <div className="mb-6">
                      <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                      <Link to="/qualify">
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                          Begin Assessment
                        </button>
                      </Link>
                    </div>
                    
                    <button 
                      onClick={() => {navigate('#apply')}}
                      className="w-auto mb-4 xl:mb-0 bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-8 py-3"
                    >
                      Become a Partner
                    </button>
                  </div>
                  :
                  null
                }

                {withOnlyCTAButton
                  ?
                  <>
                    <div className="hidden xl:flex flex-col items-center justify-center mt-8">
                      <button 
                        onClick={() => {navigate('#apply')}}
                        className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6"
                      >
                        <span className="default-text">Do You Qualify?</span>
                        <span className="hover-text mx-7">Apply Now</span>
                      </button>
                    </div>
                    
                    <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
                      <div className="mb-6">
                        <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                          <button 
                            onClick={() => {navigate('#apply')}}
                            className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3"
                          >
                            Apply Now
                          </button>
                      </div>
                    </div>
                  </>
                  :
                  null
                }

                {withNewCTAButton
                  ?
                  <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
                    <div className="mb-6">
                      <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                      <Link to="/qualify">
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                          <span className="mx-1">Begin Now</span>
                        </button>
                      </Link>
                    </div>

                    <Link to="/contact">
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-10 py-3">
                        Request a Consultation
                      </button>
                    </Link>
                  </div>
                  :
                  null
                }
              </div>
            </div>
            :
            null
          }
        </div>
      </div>

      {withButton
        ?
        null
        :
        redBubble
        ?
          <div>
            <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 -mt-16 z-20 rounded-3xl shadow-lg">
              <h2 className="text-white font-medium text-center text-lg md:text-xl">
                {redBubbleText ? redBubbleText : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean auctor sagittis tellus, sit amet auctor velit tincidunt commodo. Phasellus vel lacinia justo. Sed gravida maximus maximus. Donec tellus libero, aliquam in mauris ac, vestibulum bibendum arcu. Nam tristique a orci ornare vestibulum."}
              </h2>
            </div>
          </div>
        :
        null
      }
    </>
  )
}

export default HeroSectionPageUpdate
