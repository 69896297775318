import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"
import { navigate } from '@reach/router'
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import HubspotForm from 'react-hubspot-form'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroSectionPageUpdate from "../../components/common/hero-section-page-update";
import WistiaVideo from '../../components/common/WistiaVideo';

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const CTAButton = () => (
  <>
    <div className="hidden xl:flex flex-col items-center justify-center mt-8">
      <button 
        onClick={() => {navigate('#apply')}}
        className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6"
      >
        <span className="default-text">Do You Qualify?</span>
        <span className="hover-text mx-7">Apply Now</span>
      </button>
    </div>
    
    <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
      <div className="mb-6">
        <p className="text-site-red text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
          <button 
            onClick={() => {navigate('#apply')}}
            className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3"
          >
            Apply Now
          </button>
      </div>
    </div>
  </>
)

const PageRepresentativeTestimonials = () => {
  const data = useStaticQuery(graphql`
    query PageRepresentativeTestimonialsQuery {
      prismicTestimonialsPage {
        _previewable
        data {
          testimonials_page_title
          testimonials_page_short_description
          red_bubble_text
          intro_section_wistia_id
          page_hero_background {
            alt
            gatsbyImageData(
              width: 1920
            )
          }
          testimonial_section {
            title
            quote {
              richText
            }
            wistia_id
            video_position
            section_background_color
          }
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/hero-client-res-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicTestimonialsPage
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <Layout isLandingPage isApply isNewTestiApplyPage isContactPage>
      <Seo
        title="831(b) Advisor Testimonials"
        description={doc.data.testimonials_page_short_description}
        image={imgSrc}
      />

      <HeroSectionPageUpdate
        title="831(b) Advisor Testimonials"
        imgSrc={imgHero}
        withOnlyCTAButton
      />

      <div className="relative">
        {doc.data.red_bubble_text
          ?
          <div className="absolute top-0 w-full flex flex-col items-center -mt-16">
            <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
              <h2 className="text-white font-medium text-center text-lg md:text-xl">{doc.data.red_bubble_text}</h2>
            </div>
          </div>
          :
          null
        }

        <StyledDiv2 className="pt-24 pb-16 md:pb-24">
          <div className="w-11/12 lg:w-10/12 max-w-screen-md shadow-lg mt-16 xl:mt-12 mx-auto">
            <WistiaVideo wistiaID={doc.data.intro_section_wistia_id} />

            <CTAButton />
          </div>
        </StyledDiv2>

        {doc.data.testimonial_section.map(item => (
          <div className={`${item.section_background_color ? "bg-gray-100" : "bg-white"} pt-4 xl:pt-24 pb-12 md:pb-24`}>
            <div className={`w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col xl:flex-row items-center xl:items-start xl:justify-start mx-auto`}>
              <div className="relative w-full xl:w-1/2">
                <div className="z-0">
                  <svg className={`fill-current ${item.section_background_color ? "text-gray-200" : "text-site-green-light"} block h-48 w-60 -mb-36`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.928,3.932A9.709,9.709,0,0,0,.5,13.88v1.243a4.942,4.942,0,1,0,4.941-4.941,4.609,4.609,0,0,0-1.115.14.25.25,0,0,1-.277-.368A6.832,6.832,0,0,1,9.928,6.432a1.25,1.25,0,0,0,0-2.5Z"></path>
                    <path d="M22.25,6.432a1.25,1.25,0,0,0,0-2.5,9.71,9.71,0,0,0-9.428,9.948v1.243a4.942,4.942,0,1,0,4.942-4.941,4.611,4.611,0,0,0-1.116.14.249.249,0,0,1-.26-.092.252.252,0,0,1-.017-.276A6.832,6.832,0,0,1,22.25,6.432Z"></path>
                  </svg>
                </div>
                <div className="z-20">
                  <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider mb-8">{item.title}</h2>
                  <RichText
                    render={item.quote.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </div>
              <div className={`${item.video_position ? "order-last ml-0 xl:ml-8" : "xl:order-first mr-0 xl:mr-8"} w-full xl:w-1/2`}>
                <WistiaVideo wistiaID={item.wistia_id} />
                <CTAButton />
              </div>
            </div>
          </div>
        ))}

        <div id="apply" className="bg-white flex flex-col justify-center items-center border-t-2 border-site-red pt-6 pb-12 md:pb-16">
          <div className="w-full mx-auto px-3">
            <h2 className="text-site-red text-4xl md:text-5xl text-center font-extrabold tracking-wider mb-8 mt-16">Apply to be an SRA Representative</h2>
          </div>

          <div className="w-11/12 max-w-lg mt-12 border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg">
            <HubspotForm
              region='na1'
              portalId='23182726'
              formId='3ea06b63-05df-476a-b87c-f8703514c6d0'
              loading={<div className='italic'>Loading apply form...</div>}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(PageRepresentativeTestimonials)
